// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming








@use 'sass:map';
@use '@angular/material' as mat;

@import './variable';

$my-custom-primary: mat.define-palette(mat.$indigo-palette);
$my-custom-accent: mat.define-palette(mat.$indigo-palette);
$my-custom-warn: mat.define-palette(mat.$red-palette);
$my-custom-theme: mat.define-light-theme((color: (primary: $my-custom-primary, accent: $my-custom-accent, warn: $my-custom-warn, )));

@include mat.all-component-themes($my-custom-theme);



// /* You can add global styles to this file, and also import other style files */

* {
   font-family: "Avenir";
}

html,
body {
   height: 100%;
}

body {
   margin: 0;
   font-family: "Avenir" Roboto, "Helvetica Neue", sans-serif;
   position: relative;
}



.mat-dialog-container {
   padding: 0 !important;
   background-color: #eaeaea !important;
   max-width: 100vw !important;
}

.mat-progress-bar-buffer {
   background-color: #9fb8dc;
}

.mat-button-toggle-label-content {
   padding: 0 10px;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
   font-size: 14px !important;
   font-weight: 600 !important;
   height: 50px !important;
   background-color: rgb(255, 255, 255);
   box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
}

.mat-paginator-container {
   //background-color: #eaeaea !important;
   background-color: white !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
   line-height: 20px !important;
   padding: 0 12px !important;
}

.mat-standard-chip {
   min-height: 25px !important;
   background-color: #fff !important;
   border: solid thin #ccc !important;
   font-size: 12px;
   font-weight: 400;
}

.mat-stroked-button {
   line-height: unset !important;
   background-color: #fff !important;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
   background-color: #06262d !important;
   color: rgba(255, 255, 255, 0.87) !important;
}

.mat-flat-button.mat-accent[disabled="true"],
.mat-raised-button.mat-accent[disabled="true"],
.mat-fab.mat-accent[disabled="true"],
.mat-mini-fab.mat-accent[disabled="true"] {
   background-color: rgba(6, 38, 45, 0.30) !important;
   color: rgba(0, 0, 0, 0.26) !important;
}

.mat-calendar-body-selected {
   background-color: #06262d;
   color: white;
}

.mat-mdc-menu-panel {
   max-width: unset !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
   background-color: unset !important;
}


.mat-mdc-paginator .mat-mdc-form-field-infix {
   min-height: 30px !important;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
   padding-top: 2px !important;
   padding-bottom: 2px !important;
}