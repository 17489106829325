@font-face {
   font-family: "Avenir";
   src: url('/assets/fonts/AvenirNext-Medium.woff') format('woff'), url('/assets/fonts/AvenirNext-Medium.ttf') format("truetype"), url('/assets/fonts/AvenirNext-Medium.eot') format('embedded-opentype');
}

@font-face {
   font-family: "Avenir-Regular";
   src: url('/assets/fonts/avenir-next-regular.woff') format('woff'), url('/assets/fonts/avenir-next-regular.ttf') format("truetype"), url('/assets/fonts/avenir-next-regular.eot') format('embedded-opentype');
}

@font-face {
   font-family: "Avenir-DemiBold";
   src: url('/assets/fonts/AvenirNext-DemiBold.woff') format('woff'), url('/assets/fonts/AvenirNext-DemiBold.ttf') format("truetype"), url('/assets/fonts/AvenirNext-DemiBold.eot') format('embedded-opentype');
}