@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import './styles/fonts';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import './styles//material-customize';
@import './styles/main';
@import './styles/animation';
// Below commented code was added by default

// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @use 'sass:map';
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat-core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $demoapp-primary: mat-palette($mat-indigo);
// $demoapp-accent: mat-palette($mat-pink, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $demoapp-warn: mat-palette($mat-red);

// // Create the theme object (a Sass map containing all of the palettes).
// $demoapp-theme: mat-light-theme($demoapp-primary, $demoapp-accent, $demoapp-warn);

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($demoapp-theme);

// /* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }