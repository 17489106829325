.debug{
   border: solid thin red;
}

@for $i from 0 through 100{
    .wd-#{$i}{
        width:#{percentage($i/100)};
    }
}

@for $i from 10 through 30{
   .font-#{$i}{
       font-size: ($i)+px;
   }
}

@for $i from 0 through 50 {
    .lh-#{$i}{
        line-height: ($i) + px !important;
    }
    .p-#{$i}{
       padding: ($i) + px !important;
    }
    .p-t-#{$i}{
        padding-top: ($i) + px !important;
     }
     .p-b-#{$i}{
        padding-bottom: ($i) + px !important;
     }
     .p-tb-#{$i}{
        padding-top: ($i) + px !important;
        padding-bottom: ($i) + px !important;
     }
     .p-l-#{$i}{
        padding-left: ($i) + px !important;
     }
     .p-r-#{$i}{
        padding-right: ($i) + px !important;
     }
     .p-lr-#{$i}{
        padding-left: ($i) + px !important;
        padding-right: ($i) + px !important;
     }
    .m-#{$i}{
        margin: ($i) + px !important;
     }
     .m-t-#{$i}{
        margin-top: ($i) + px !important;
     }
     .m-b-#{$i}{
        margin-bottom: ($i) + px !important;
     }
     .m-tb-#{$i}{
        margin-top: ($i) + px !important;
        margin-bottom: ($i) + px !important;
     }
     .m-l-#{$i}{
        margin-left: ($i) + px !important;
     }
     .m-r-#{$i}{
        margin-right: ($i) + px !important;
     }
     .m-lr-#{$i}{
        margin-left: ($i) + px !important;
        margin-right: ($i) + px !important;
     }
}

.pointer{
   cursor: pointer;
}

.pos-rel{
   position: relative;
}

.wd-100{
   width: 100%;
}
.app-workfow-header{
   color: #8f8f8f;
   font-size: 14px;
   font-weight: 500;   
}
.body-background{
   background-color: #eaeaea;
}
.dark-blue{
   color:#06262D;
}
.dark-blue-bg{
   background-color: #06262D;
}
.medium-dark-blue{
   color:#0078d4;
}
.medium-dark-blue-bg{
   background-color:#0078d4;
}
.light-blue{
   color:#BEC4EC;
}
.light-blue-bg{
   background-color: #BEC4EC;
}
.white{
   color: #fff;
}
.white-bg{
   background-color: #fff;
}
.dark-gray{
   color:#9B9B9E
}
.dark-gray-bg{
   background-color: #9B9B9E;
}
.light-gray{
   color:#bbb    
}
.light-gray-bg{
   background-color: #bbb;
}
.very-dark-gray{
   color: #323130;
}
.black {
   color: #000;
}

.errorMsg{
   color: #B71F3A ;
}
.errBG{
   background-color: #B71F3A;
}
.invalidrecord{
   background-color: rgba(255, 128, 128, 0.2);
 }
 .act-title{
    color: #4810c5;
 }
.white{
   color: #fff;
}
.bold{
   font-weight: 500;;
}
.strong{
   font-weight: 600;;
}
.bolder{
   font-weight: 700;;
}
.md-font{
   font-size: 10px;
}

.md-lg-font{
   font-size: 12px;
}

.header-1{
   font-size: 16px;
   color: #0078d4;
   font-weight: bold;
}
.header-2{
   font-size: 14px;
   color: #0078d4;
   font-weight: bold;
}

.overflow-ellipsis{
   white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.border-t{
   border-top:  solid thin #eaeaea;
}
.border-b{
   border-bottom:  solid thin #eaeaea;
}

.border-l{
   border-left:  solid thin #eaeaea;
}
.border-t-gray{
   border-top: solid thin #ccc;
}
.border-b-gray{
   border-bottom: solid thin #ccc;
}

.border-selected-tab{
   border-bottom: solid 3px #005CB8;
}
.overflow-y-auto{
   overflow-y: auto;
}
.rec-updated{
   border-left: solid 4px #005CB8;
}
.model-header{
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   align-content: stretch;
   color: white;
   background: linear-gradient(#857f7f, #e6e5e5);
}
.flex-1{
   flex:1
}
.spacer{
   flex-grow: 1;
   flex-shrink: 1;
   flex-basis: auto;
}
.center-align-container{
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   align-content: stretch;
}
.flex-row-space-between-wrap{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;
   align-content: stretch;
}

.flex-row-space-around{
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-around;
   align-items: stretch;
   align-content: stretch;
}

.flex-row-container-start{
   display: flex;
   flex-direction: row;   
}
.flex-row-container-start-wrap{
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;
}
.flex-row-container{
   display: flex;
   flex-direction: row;
   align-items: center;
}

.flex-col-container{
   display: flex;
   flex-direction: column;
}
.flex-row-container-right-align{
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
}

.flex-row-container-right-end{
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: flex-end;
}

.flex-space-between {
   display: flex;
   justify-content: space-between;
}

.flex-col-center{
   display: flex;
   flex-direction: column;
   align-items: center;
}

.flex-col-end{
   display: flex;
   flex-wrap: nowrap;
   flex-direction: column;
   justify-content: center;
   align-items: flex-end;
   align-content: stretch;
}
.text-align-right{
   text-align: right;
}

.text-align-center{
   text-align: center;
}
.text-underline{
   text-decoration: underline;
}
.back-drop{
   background-color: #fafafa;;
 }

 /*effect-underline*/
.hover-effect{
   transition:all 0.3s ease;
}
.hover-effect:hover{
   background:#bfe3ff;
}

tr:hover{
   background:#eee;
}

th{
   text-transform: uppercase;
}
.success-snackbar {
   background-color:green;
   color: whitesmoke;
   --mdc-snackbar-container-color: green !important;
}
.error-snackbar {
   background-color:red;
   color: whitesmoke;
   --mdc-snackbar-container-color: red !important;   
}
.mat-mdc-snack-bar-label{
   text-align: center !important;
   }
.warning-snackbar{
   background-color:orange;
   color: whitesmoke;
   --mdc-snackbar-container-color: orange !important;
}

.success{
   color:green;   
}
.error{
   color:red;   
}
.warning{
   color: orange;
}

.box-shadow{
   -webkit-box-shadow: 0px 3px 10px -1px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 3px 10px -1px rgba(0,0,0,0.75);
box-shadow: 0px 3px 10px -1px rgba(0,0,0,0.75); 
}
.hover-sortable-header{
   background: inherit;
}
.hover-sortable-header:hover{
   transition:all 0.3s linear;
   background-color: #127187;
   //opacity: 0.7;
}
.sort-icon{
   font-weight: 100;
   color: lightgray;
   font-size: medium;
}

.restriction-icon{
   background-color: red;
   color: white;
   width: 15px;
   height: 15px;
   border: solid thin red;
   border-radius: 50%;   
   font-weight: bold;
   display: inline-block;
   text-align: center;
   box-shadow: 3px 3px 2px grey;
}
.mat-chip>span:hover{
   background-color: darkgray;
   border-radius: 5px;   
}

.tag{
   width: 12px;
   height: 12px;
   border-radius: 50%;
}

.legend{
   width: 12px;
   height: 12px;   
}

.smalltag{
   width: 8px;
   height: 8px;
   border-radius: 50%;
}
.text-underline{
   text-decoration:underline;
}
.spin-image {
  
   -webkit-animation:spin 1s linear infinite;
   -moz-animation:spin 1s linear infinite;
   animation:spin 1s linear infinite;
}
.mat-mdc-dialog-actions{
   justify-content: space-between !important;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }